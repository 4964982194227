import { Component, OnInit } from '@angular/core';
import { LoaderService } from './services/loader/loader.service';
import { StorageService, StorageTempate } from './services/storage/storage.service';
import { PrimeNGConfig } from 'primeng/api';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  constructor(
    private loaderService: LoaderService,
    private storageService: StorageService,
    private primengConfig: PrimeNGConfig
  ) { }

  public loading$ = this.loaderService.loading$;
  title = 'Traxphere';

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

}
